import { type VariantProps } from "cva";
import { type SVGAttributes } from "react";

import { cva, cx } from "@/lib/utils";

export type SpinnerIconProps = SVGAttributes<SVGElement> & VariantProps<typeof variants>;

const variants = cva({
  base: "size-4",
});

export function SpinnerIcon({ className, ...props }: SpinnerIconProps) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(variants(), className)}
      {...props}
    >
      <path
        className="opacity-20"
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
      />
      <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" />
    </svg>
  );
}
