"use client";

import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { Button } from "@/components/button";
import { useFilterContext } from "@/contexts/filter-context";
import { useFilter } from "@/hooks/use-filter";
import { cva, cx } from "@/lib/utils";

export type NoResultsProps = HTMLAttributes<HTMLElement> & VariantProps<typeof variants>;

const variants = cva({
  base: "flex flex-col items-center justify-center gap-y-2 rounded-md bg-gradient-to-t from-brand-gray-400 from-60% to-white p-12 text-center",
});

export function NoResults({ className, ...props }: NoResultsProps) {
  const { isActive } = useFilterContext();
  const { resetFilters } = useFilter();

  return (
    <div className={cx(variants(), className)} {...props}>
      <p className="font-h1">No results found.</p>
      <p>Try removing some or all of your filters.</p>
      {isActive && (
        <Button onPress={resetFilters} variant="outline" theme="secondary" className="border-none">
          Reset Filters
        </Button>
      )}
    </div>
  );
}
