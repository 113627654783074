"use client";

import { IconList } from "@tabler/icons-react";

import { type EventsView } from "@/components/events";
import { Field } from "@/components/forms/field";
import { Label } from "@/components/forms/label";
import { Select } from "@/components/forms/select";
import { useFilterContext } from "@/contexts/filter-context";
import { useFilter } from "@/hooks/use-filter";
import { type Field as FieldType, type FilterField } from "@/lib/forms";
import { isDefinedAndNotEmpty } from "@/lib/utils";

const filterName: FilterField = "view";

export function ViewFilter() {
  const { isLoading, getCurrentFilterValue } = useFilterContext();
  const { updateFilters } = useFilter();

  const views = [
    { label: "Event view", value: "event" },
    { label: "Milestone view", value: "milestone" },
  ] satisfies { label: string; value: EventsView }[];

  const defaultView = views.find((view) => view.value === "event");
  const currentViewFilter = getCurrentFilterValue(filterName);

  const options = views.reduce<FieldType>((options, { label, value }) => {
    if (isDefinedAndNotEmpty(value)) options[value] = { label: label, value: value };

    return options;
  }, {});

  return (
    <Field className="py-0">
      <Label className="sr-only">Select View</Label>
      <Select
        name={filterName}
        icon={IconList}
        placeholder={defaultView?.label}
        selectedKey={currentViewFilter ?? null}
        onSelectionChange={(key) => {
          updateFilters([{ name: filterName, values: [String(key)] }]);
        }}
        options={options}
        isDisabled={isLoading}
        isActive={isDefinedAndNotEmpty(currentViewFilter)}
      />
    </Field>
  );
}
