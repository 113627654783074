import { type VariantProps } from "cva";

import { SpinnerIcon, type SpinnerIconProps } from "@/components/svg/spinner-icon";
import { cva, cx } from "@/lib/utils";

export type SpinnerProps = SpinnerIconProps & VariantProps<typeof variants>;

const variants = cva({
  base: "size-4 flex-shrink-0 animate-spin text-brand-red",
});

export function Spinner({ className, ...props }: SpinnerProps) {
  return <SpinnerIcon className={cx(variants(), className)} {...props} />;
}
