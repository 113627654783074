"use client";

import { IconChevronDown, type TablerIcon } from "@tabler/icons-react";
import { type VariantProps } from "cva";
import {
  Button,
  ListBox,
  ListBoxItem,
  Popover,
  Select as ReactAriaSelect,
  type SelectProps as ReactAriaSelectProps,
  SelectValue,
} from "react-aria-components";

import { type Field, type FormField } from "@/lib/forms";
import { cva, cx, isDefinedAndNotEmpty, objectValues } from "@/lib/utils";

export type SelectProps = ReactAriaSelectProps<HTMLSelectElement> &
  VariantProps<typeof variants> & {
    name: FormField;
    icon?: TablerIcon;
    options: Field;
    isActive?: boolean;
  };

export const variants = cva({
  base: "w-full bg-transparent text-brand-gray-600",
});

export function Select({ icon: Icon, options, isActive, className, ...props }: SelectProps) {
  return (
    isDefinedAndNotEmpty(options) && (
      <ReactAriaSelect className={cx(variants(), className)} {...props}>
        <Button
          className={cx(
            "no-focus focus-ring flex w-fit items-center justify-between gap-x-1 rounded-md bg-brand-gray-400 px-4 py-2.5 text-left text-current transition-colors",
            isActive && "bg-brand-red-200",
          )}
        >
          {isDefinedAndNotEmpty(Icon) && (
            <span aria-hidden="true">
              <Icon strokeWidth={1.5} className="size-5" />
            </span>
          )}
          <SelectValue />
          <span aria-hidden="true">
            <IconChevronDown className="size-4" />
          </span>
        </Button>
        <Popover>
          <ListBox className="no-focus flex max-h-[40vh] min-w-20 max-w-80 flex-col gap-y-2 overflow-auto rounded-md bg-brand-gray-400 px-4 py-2.5 text-brand-gray-600 shadow-md">
            {objectValues(options).map(({ label, value }) => {
              return (
                <ListBoxItem
                  key={value || label}
                  id={value || label}
                  className="focus-ring cursor-default data-[selected]:text-brand-red data-[disabled]:opacity-30"
                >
                  {label}
                </ListBoxItem>
              );
            })}
          </ListBox>
        </Popover>
      </ReactAriaSelect>
    )
  );
}
