"use client";

import { IconCategory } from "@tabler/icons-react";

import { Field } from "@/components/forms/field";
import { Label } from "@/components/forms/label";
import { Select } from "@/components/forms/select";
import { useFilterContext } from "@/contexts/filter-context";
import { useFilter } from "@/hooks/use-filter";
import { type Field as FieldType, type FilterField } from "@/lib/forms";
import { isDefinedAndNotEmpty } from "@/lib/utils";
import { type SanityEvent } from "@/sanity/schemas/documents/collections/event";
import { type SanityTheme } from "@/sanity/schemas/documents/collections/theme";
import { type SanityResource } from "@/sanity/schemas/documents/entries/resource";

export type ThemeFilterProps = {
  filterBy: SanityEvent["_type"] | SanityResource["_type"];
  themes: SanityTheme[];
};

const filterName: FilterField = "theme";

export function ThemeFilter({ filterBy, themes }: ThemeFilterProps) {
  const { isLoading, getCurrentFilterValue } = useFilterContext();
  const { updateFilters } = useFilter();

  const currentThemeFilter = getCurrentFilterValue(filterName);

  const countKeys: Record<typeof filterBy, keyof SanityTheme> = {
    event: "eventsCount",
    resource: "resourcesCount",
  };

  const options = themes.reduce<FieldType>((options, theme) => {
    const { title, slug } = theme;

    if (isDefinedAndNotEmpty(title) && isDefinedAndNotEmpty(slug))
      options[slug.current] = {
        label: `${title} (${theme[countKeys[filterBy]]})`,
        value: slug.current,
      };

    return options;
  }, {});

  const disabledKeys = themes.reduce<string[]>((disabledKeys, theme) => {
    const { slug } = theme;

    if (isDefinedAndNotEmpty(slug) && theme[countKeys[filterBy]] === 0)
      disabledKeys.push(slug.current);

    return disabledKeys;
  }, []);

  return (
    <Field className="py-0">
      <Label className="sr-only">Select Theme</Label>
      <Select
        name={filterName}
        icon={IconCategory}
        placeholder="Theme"
        selectedKey={currentThemeFilter ?? null}
        onSelectionChange={(key) => {
          updateFilters([{ name: filterName, values: [String(key)] }]);
        }}
        options={options}
        disabledKeys={disabledKeys}
        isDisabled={isLoading}
        isActive={isDefinedAndNotEmpty(currentThemeFilter)}
      />
    </Field>
  );
}
