"use client";

import { type VariantProps } from "cva";
import { Checkbox, CheckboxGroup, type CheckboxGroupProps } from "react-aria-components";

import { useFilterContext } from "@/contexts/filter-context";
import { useFilter } from "@/hooks/use-filter";
import { getDate, getMonthNumber, getMonths } from "@/lib/dates";
import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";
import { type SanityEvent } from "@/sanity/schemas/documents/collections/event";

export type EventCalendarProps = CheckboxGroupProps &
  VariantProps<typeof variants> & {
    events: SanityEvent[] | undefined;
    calendarEvents: SanityEvent[] | undefined;
  };

export const variants = cva({});

export function EventCalendar({ events, calendarEvents, className, ...props }: EventCalendarProps) {
  const currentYear = getDate().getFullYear();
  const currentMonth = getDate().getMonth() + 1;
  const months = getMonths("short");

  const { currentFilters, isLoading, isActive, getCurrentFilterValue, getCurrentFilterValues } =
    useFilterContext();
  const { updateFilters } = useFilter();

  const currentYearFilter = getCurrentFilterValue("year") || String(currentYear);
  const currentMonthFilters = getCurrentFilterValues("month");
  const showPastEvents = isDefinedAndNotEmpty(getCurrentFilterValue("past-events"));

  const isPastEventFilterExclusive = () => {
    if (!isDefinedAndNotEmpty(currentFilters)) return false;

    return currentFilters.every((currentFilter) => currentFilter.name === "past-events");
  };

  const onMonthChange = (values: string[]) => {
    const value = values.at(-1);

    const isPastMonth = Number(value) < currentMonth;

    updateFilters([
      { name: "month", values: value ? [value] : [] },
      { name: "past-events", values: isPastMonth ? ["true"] : [] },
    ]);
  };

  const getEventsInMonth = (month: number) => {
    return calendarEvents?.filter((event) => {
      if (Number(event.year) !== Number(currentYearFilter)) return false;

      return Number(event.month) === month;
    });
  };

  return (
    <CheckboxGroup
      value={currentMonthFilters ?? []}
      onChange={onMonthChange}
      className={cx(variants(), className)}
      {...props}
    >
      <div className="flex snap-x gap-1 overflow-x-auto">
        {months.map((month, index) => {
          const monthNumber = getMonthNumber(index + 1);
          const eventsInMonth = getEventsInMonth(Number(monthNumber));

          return (
            <Checkbox
              key={index}
              value={monthNumber}
              isDisabled={isLoading}
              className={cx(
                "focus-border no-focus flex aspect-square min-w-24 flex-1 cursor-pointer snap-center flex-col items-center justify-end gap-y-2 rounded-md bg-brand-gray-400 px-4 py-2 text-brand-gray-900 transition-colors data-[selected]:bg-brand-red-200 @max-container/main:min-w-max",
              )}
            >
              <div className="flex flex-wrap gap-1">
                {eventsInMonth?.map(({ _id, date, isPast }, index) => {
                  const isFilteredEvent = events?.some((event) => {
                    if (showPastEvents) return event._id === _id;
                    else return event._id === _id && !isPast;
                  });

                  const isActiveEvent =
                    isFilteredEvent && isActive && !isPastEventFilterExclusive();

                  return (
                    <span
                      key={index}
                      className={cx(
                        "block size-2 rounded-full bg-current text-brand-gray-900 transition-colors",
                        getDate(date) < getDate() && !showPastEvents && "text-brand-gray-500",
                        isActiveEvent && "text-brand-red",
                      )}
                    />
                  );
                })}
              </div>
              <span>{month}</span>
            </Checkbox>
          );
        })}
      </div>
    </CheckboxGroup>
  );
}
