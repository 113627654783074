"use client";

import { type VariantProps } from "cva";
import { Fragment, type HTMLAttributes, useEffect } from "react";

import { Event } from "@/components/event";
import { Milestones } from "@/components/milestones";
import { NoResults } from "@/components/no-results";
import { useFilterContext } from "@/contexts/filter-context";
import { getMonth } from "@/lib/dates";
import { maximumEventsOnHomepage } from "@/lib/settings";
import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";
import { type SanityEvent } from "@/sanity/schemas/documents/collections/event";

export type EventsProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    events: SanityEvent[] | undefined;
  };

export type EventsView = "event" | "milestone";

export const variants = cva({
  variants: {
    display: {
      full: "",
      limited: "relative max-h-[80rem] overflow-y-hidden lg:max-h-[40rem]",
    },
  },
  defaultVariants: {
    display: "full",
  },
});

export function Events({ events, display, className, ...props }: EventsProps) {
  const { setIsLoading, getCurrentFilterValue } = useFilterContext();

  const currentView = (getCurrentFilterValue("view") || "events") as EventsView;

  useEffect(() => {
    setIsLoading(false);
  }, [events, setIsLoading]);

  const milestones = events?.flatMap(({ milestones }) => milestones || []);

  return (
    <div className={cx(variants({ display }), className)} {...props}>
      {display === "limited" &&
        isDefinedAndNotEmpty(events) &&
        events.length - 1 <= maximumEventsOnHomepage && (
          <div className="pointer-events-none absolute inset-0 z-10 bg-gradient-to-t from-white via-transparent via-40% to-transparent" />
        )}
      {isDefinedAndNotEmpty(events) ? (
        currentView === "milestone" ? (
          <Milestones milestones={milestones} />
        ) : (
          events.map((event, index) => {
            const { month } = event;
            const previousEvent = index - 1 >= 0 ? events[index - 1] : undefined;
            const hasMonthHeading = month !== previousEvent?.month;

            return (
              <Fragment key={index}>
                {isDefinedAndNotEmpty(month) && hasMonthHeading && (
                  <h3 className="border-b border-brand-red pb-2 text-brand-red">
                    {getMonth(Number(month) - 1)}
                  </h3>
                )}
                <Event
                  event={event}
                  className={cx("py-8", !hasMonthHeading && "border-t border-brand-gray-900")}
                />
              </Fragment>
            );
          })
        )
      ) : (
        <NoResults />
      )}
    </div>
  );
}
