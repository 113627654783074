"use client";

import { IconCalendarClock } from "@tabler/icons-react";

import { CheckboxGroup } from "@/components/forms/checkbox-group";
import { Field } from "@/components/forms/field";
import { Label } from "@/components/forms/label";
import { useFilterContext } from "@/contexts/filter-context";
import { useFilter } from "@/hooks/use-filter";
import { type FilterField } from "@/lib/forms";

const filterName: FilterField = "past-events";

export function PastEventsFilter() {
  const { isLoading, getCurrentFilterValues } = useFilterContext();
  const { updateFilters } = useFilter();

  const pastEventsFilter = getCurrentFilterValues(filterName);

  return (
    <Field className="py-0">
      <Label className="sr-only">Show past events</Label>
      <CheckboxGroup
        name={filterName}
        value={pastEventsFilter ?? []}
        onChange={(values) => {
          const value = values.at(-1);
          updateFilters([{ name: filterName, values: value ? [value] : [] }]);
        }}
        options={{
          pastEvents: { label: "Show past events", value: "true", icon: IconCalendarClock },
        }}
        isDisabled={isLoading}
      />
    </Field>
  );
}
