import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { getDatestamp } from "@/lib/dates";
import { cva, cx } from "@/lib/utils";

export type DatestampProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    date: string;
  };

const variants = cva({});

export function Datestamp({ date, className, ...props }: DatestampProps) {
  const datestamp = getDatestamp(date);

  return (
    <span className={cx(variants(), className)} {...props}>
      {datestamp}
    </span>
  );
}
