"use client";

import { IconCheck, type TablerIcon } from "@tabler/icons-react";
import { type VariantProps } from "cva";
import {
  Checkbox as ReactAriaCheckbox,
  type CheckboxProps as ReactAriaCheckboxProps,
} from "react-aria-components";

import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";

export type CheckboxProps = ReactAriaCheckboxProps &
  VariantProps<typeof variants> & {
    icon?: TablerIcon;
  };

export const variants = cva({
  base: "no-focus focus-ring group flex w-fit items-center justify-between gap-x-2 rounded-md bg-brand-gray-400 px-4 py-2.5 text-left text-brand-gray-600 transition-colors data-[selected]:bg-brand-red-200",
});

export function Checkbox({ icon: Icon, children, className, ...props }: CheckboxProps) {
  return (
    <ReactAriaCheckbox className={cx(variants(), className)} {...props}>
      {isDefinedAndNotEmpty(Icon) && (
        <span aria-hidden="true">
          <Icon strokeWidth={1.5} className="size-5" />
        </span>
      )}
      <>{children}</>
      <span
        className="flex size-3 flex-shrink-0 items-center justify-center rounded-sm border border-current"
        aria-hidden="true"
      >
        <IconCheck
          strokeWidth={2.5}
          className="size-2.5 opacity-0 transition-opacity group-data-[selected]:opacity-100"
        />
      </span>
    </ReactAriaCheckbox>
  );
}
