import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { EventCalendar } from "@/components/event-calendar";
import { EventFilters } from "@/components/event-filters";
import { Events } from "@/components/events";
import { variants as sectionVariants } from "@/components/section";
import { cva, cx } from "@/lib/utils";
import { type SanityEvent } from "@/sanity/schemas/documents/collections/event";
import { type SanityTheme } from "@/sanity/schemas/documents/collections/theme";
import { type SanityTopic } from "@/sanity/schemas/documents/collections/topic";
import { type SanityYear } from "@/sanity/types";

export type EventRoadmapProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    themes?: SanityTheme[];
    topics?: SanityTopic[];
    events: SanityEvent[] | undefined;
    calendarEvents: SanityEvent[] | undefined;
    years: SanityYear[] | undefined;
    display?: "full" | "limited";
  };

const variants = cva({
  base: "space-y-6",
});

export function EventRoadmap({
  themes,
  topics,
  events,
  calendarEvents,
  years,
  className,
  display,
  ...props
}: EventRoadmapProps) {
  return (
    <div className={cx(sectionVariants(), variants(), className)} {...props}>
      <EventCalendar events={events} calendarEvents={calendarEvents} />
      <EventFilters themes={themes} topics={topics} years={years} />
      <Events events={events} display={display} />
    </div>
  );
}
