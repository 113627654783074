import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { Accordion } from "@/components/accordion";
import { ButtonLink } from "@/components/button";
import { Datestamp } from "@/components/datestamp";
import { Image } from "@/components/image";
import { PortableText } from "@/components/portable/portable-text";
import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";
import { resolveSanityUrl } from "@/sanity/lib/utils";
import {
  type SanityEvent,
  type SanityEventMilestone,
} from "@/sanity/schemas/documents/collections/event";

export type EventProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    event: SanityEvent;
  };

type MilestonePreviewProps = {
  milestone: SanityEventMilestone;
};

export const variants = cva({
  base: "grid grid-cols-12 gap-y-8 md:gap-x-8 2xl:gap-x-24",
});

export function Event({ event, className, ...props }: EventProps) {
  const { title, slug, date, location, heading, image, milestones, isPast } = event;

  const milestonePreviews = milestones?.slice(0, 2);
  const remainingMilestones = milestones?.slice(2);

  return (
    <div className={cx(variants(), className)} {...props}>
      <div className="col-span-12 md:col-span-4 2xl:col-span-3">
        {isDefinedAndNotEmpty(image) && (
          <div>
            <Image image={image} />
          </div>
        )}
      </div>
      <div className="col-span-8 space-y-6 2xl:col-span-3">
        <div className="space-y-2">
          {isPast && (
            <p className="w-fit rounded-full bg-brand-red-200 px-2.5 py-0.5 text-sm">Past Event</p>
          )}
          <div className="flex gap-x-3 text-sm">
            {isDefinedAndNotEmpty(date) && <Datestamp date={date} className="peer" />}
            {isDefinedAndNotEmpty(location) && (
              <span className="peer-first:border-l peer-first:border-current peer-first:pl-3">
                {location}
              </span>
            )}
          </div>
          <h3>{heading || title}</h3>
        </div>
        <ButtonLink
          href={resolveSanityUrl({ type: "event", slug: slug?.current })}
          isExternal={true}
          variant="outline"
          theme="secondary"
        >
          Go to event
        </ButtonLink>
      </div>
      <div className="col-span-12 space-y-4 2xl:col-span-6">
        {isDefinedAndNotEmpty(milestones) && (
          <>
            <h4>Milestones</h4>
            {isDefinedAndNotEmpty(milestonePreviews) &&
              milestonePreviews.map((milestone, index) => {
                return <MilestonePreview key={index} milestone={milestone} />;
              })}
            {isDefinedAndNotEmpty(remainingMilestones) && (
              <Accordion
                header={(isOpen) => (
                  <p className="text-brand-red">{!isOpen ? "See more" : "See less"}</p>
                )}
                showArrow={false}
                headerPosition="bottom"
              >
                <div className="space-y-4">
                  {remainingMilestones.map((milestone, index) => {
                    return <MilestonePreview key={index} milestone={milestone} />;
                  })}
                </div>
              </Accordion>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function MilestonePreview({ milestone }: MilestonePreviewProps) {
  const { topic, description } = milestone;
  const { title } = topic || {};

  return (
    <div className="space-y-2 text-brand-gray-600">
      {isDefinedAndNotEmpty(title) && <h4 className="font-h5">{title}</h4>}
      {isDefinedAndNotEmpty(description) && <PortableText value={description} />}
    </div>
  );
}
