"use client";

import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useContext,
  useState,
} from "react";

type AccordionGroupContextValues = {
  openAccordionId: string | undefined;
  setOpenAccordionId: Dispatch<SetStateAction<string | undefined>>;
  allowMultiple?: boolean;
};

export type AccordionGroupProviderProps = {
  children: ReactNode;
  allowMultiple?: boolean;
};

const AccordionGroupContext = createContext<AccordionGroupContextValues | undefined>(undefined);

export function AccordionGroupProvider({ children, allowMultiple }: AccordionGroupProviderProps) {
  const [openAccordionId, setOpenAccordionId] = useState<string | undefined>();

  return (
    <AccordionGroupContext.Provider
      value={{
        openAccordionId,
        setOpenAccordionId,
        allowMultiple,
      }}
    >
      {children}
    </AccordionGroupContext.Provider>
  );
}

export function useAccordionGroupContext() {
  const accordionGroupContext = useContext(AccordionGroupContext);

  return accordionGroupContext;
}
