"use client";

import { type VariantProps } from "cva";
import {
  CheckboxGroup as ReactAriaCheckboxGroup,
  type CheckboxGroupProps as ReactAriaCheckboxGroupProps,
} from "react-aria-components";

import { Checkbox } from "@/components/forms/checkbox";
import { Label } from "@/components/forms/label";
import { type Field, type FormField } from "@/lib/forms";
import { cva, cx, objectValues } from "@/lib/utils";

export type CheckboxGroupProps = ReactAriaCheckboxGroupProps &
  VariantProps<typeof variants> & {
    name: FormField;
    options: Field;
  };

export const variants = cva({
  base: "flex gap-x-2",
});

export function CheckboxGroup({ options, className, ...props }: CheckboxGroupProps) {
  return (
    <ReactAriaCheckboxGroup className={cx(variants(), className)} {...props}>
      {objectValues(options).map(({ label, value, icon }) => {
        return (
          <Checkbox key={value || label} value={value || label} icon={icon}>
            <Label>{label}</Label>
          </Checkbox>
        );
      })}
    </ReactAriaCheckboxGroup>
  );
}
