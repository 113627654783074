"use client";

import { type VariantProps } from "cva";
import {
  Label as ReactAriaLabel,
  type LabelProps as ReactAriaLabelProps,
} from "react-aria-components";

import { cva, cx } from "@/lib/utils";

export type LabelProps = ReactAriaLabelProps & VariantProps<typeof variants>;

export const variants = cva({});

export function Label({ children, className, ...props }: LabelProps) {
  return (
    <ReactAriaLabel className={cx(variants(), className)} {...props}>
      {children}
    </ReactAriaLabel>
  );
}
