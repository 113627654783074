"use client";

import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useSearchParamsContext } from "@/contexts/search-params-context";
import { type Filter } from "@/hooks/use-filter";
import { type FilterField } from "@/lib/forms";
import { isDefinedAndNotEmpty } from "@/lib/utils";

type FilterContextValues = {
  currentFilters: Filter[];
  setCurrentFilters: Dispatch<SetStateAction<Filter[]>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isActive: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  getCurrentFilter: (name: FilterField) => Filter | undefined;
  getCurrentFilterValue: (name: FilterField) => string | undefined;
  getCurrentFilterValues: (name: FilterField) => string[] | undefined;
  getCurrentFiltersFromSearchParams: () => Filter[] | undefined;
};

type FilterProviderProps = {
  children: ReactNode;
};

const FilterContext = createContext<FilterContextValues | undefined>(undefined);

export function FilterProvider({ children }: FilterProviderProps) {
  const { searchParams, searchParamKeys } = useSearchParamsContext();

  const currentFiltersFromSearchParams = useRef<Filter[]>();

  const [currentFilters, setCurrentFilters] = useState<Filter[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  const getCurrentFilter = (name: FilterField) => {
    return currentFilters.find((currentFilter) => currentFilter.name === name);
  };

  const getCurrentFilterValue = (name: FilterField) => {
    return getCurrentFilter(name)?.values?.at(-1);
  };

  const getCurrentFilterValues = (name: FilterField) => {
    return getCurrentFilter(name)?.values;
  };

  const getCurrentFiltersFromSearchParams = useCallback(() => {
    const params = new URLSearchParams(searchParams);

    return searchParamKeys.reduce<Filter[]>((filters, key) => {
      const values = params.getAll(key);

      if (isDefinedAndNotEmpty(values)) filters.push({ name: key, values });

      return filters;
    }, []);
  }, [searchParamKeys, searchParams]);

  useEffect(() => {
    currentFiltersFromSearchParams.current = getCurrentFiltersFromSearchParams();
  }, [getCurrentFiltersFromSearchParams]);

  useEffect(() => {
    if (isDefinedAndNotEmpty(currentFiltersFromSearchParams.current))
      setCurrentFilters(currentFiltersFromSearchParams.current);
  }, []);

  return (
    <FilterContext.Provider
      value={{
        currentFilters,
        setCurrentFilters,
        isLoading,
        setIsLoading,
        isActive,
        setIsActive,
        getCurrentFilter,
        getCurrentFilterValue,
        getCurrentFilterValues,
        getCurrentFiltersFromSearchParams,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export function useFilterContext() {
  const filterContext = useContext(FilterContext);

  if (!filterContext) throw new Error("useFilterContext can only be used within <FilterProvider>");

  return filterContext;
}
