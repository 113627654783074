"use client";

import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { Button } from "@/components/button";
import { PastEventsFilter } from "@/components/filters/past-events";
import { ThemeFilter } from "@/components/filters/theme";
import { TopicFilter } from "@/components/filters/topic";
import { ViewFilter } from "@/components/filters/view";
import { YearFilter } from "@/components/filters/year";
import { Spinner } from "@/components/spinner";
import { useFilterContext } from "@/contexts/filter-context";
import { useFilter } from "@/hooks/use-filter";
import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";
import { type SanityTheme } from "@/sanity/schemas/documents/collections/theme";
import { type SanityTopic } from "@/sanity/schemas/documents/collections/topic";
import { type SanityYear } from "@/sanity/types";

export type EventFiltersProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    themes: SanityTheme[] | undefined;
    topics: SanityTopic[] | undefined;
    years: SanityYear[] | undefined;
  };

export const variants = cva({
  base: "flex flex-wrap items-center justify-between gap-4",
});

export function EventFilters({ themes, topics, years, className, ...props }: EventFiltersProps) {
  const { isLoading, isActive } = useFilterContext();
  const { resetFilters } = useFilter();

  return (
    <div className={cx(variants(), className)} {...props}>
      <div className="flex flex-wrap items-center gap-4">
        {isDefinedAndNotEmpty(themes) && <ThemeFilter filterBy="event" themes={themes} />}
        {isDefinedAndNotEmpty(topics) && <TopicFilter filterBy="event" topics={topics} />}
        {isDefinedAndNotEmpty(years) && <YearFilter years={years} />}
        <PastEventsFilter />
      </div>
      <div className="flex items-center gap-x-4">
        {isLoading && <Spinner />}
        {isActive && (
          <Button
            onPress={resetFilters}
            variant="outline"
            theme="secondary"
            className="border-none"
          >
            Reset Filters
          </Button>
        )}
        <ViewFilter />
      </div>
    </div>
  );
}
