import { IconAlertOctagon } from "@tabler/icons-react";
import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { PortableText } from "@/components/portable/portable-text";
import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";
import { type SanityEventMilestone } from "@/sanity/schemas/documents/collections/event";

export type MilestoneProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    milestone: SanityEventMilestone;
  };

export const variants = cva({
  base: "border-t-2 p-6",
});

export function Milestone({ milestone, className, ...props }: MilestoneProps) {
  const { topic, description, highPriority, theme, event } = milestone;

  return (
    <div
      className={cx(variants(), className)}
      style={{
        borderColor: theme?.color,
      }}
      {...props}
    >
      <div className="flex h-full flex-col gap-y-12">
        <div className="mb-0 mt-auto space-y-4">
          {isDefinedAndNotEmpty(event) && (
            <p className="w-fit rounded-md border border-current px-2 py-1.5 text-sm text-brand-gray-600">
              {event.title}
            </p>
          )}
          <h2>{isDefinedAndNotEmpty(topic) && topic.title}</h2>
        </div>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h4>Milestone</h4>
            {highPriority && (
              <p className="flex gap-x-1 text-brand-red">
                <IconAlertOctagon strokeWidth={2} className="size-5" />
                High Priority
              </p>
            )}
          </div>
          {isDefinedAndNotEmpty(description) && <PortableText value={description} />}
        </div>
      </div>
    </div>
  );
}
