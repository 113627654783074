"use client";

import { createContext, type ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { useAccordionGroupContext } from "@/contexts/accordion-group-context";

type AccordionContextValues = {
  isAccordionOpen: boolean;
  openAccordion: () => void;
  closeAccordion: () => void;
  toggleAccordion: () => void;
};

type AccordionProviderProps = {
  id: string;
  isOpen?: boolean;
  defaultOpen?: boolean;
  children: ReactNode;
};

const AccordionContext = createContext<AccordionContextValues | undefined>(undefined);

export function AccordionProvider({
  id,
  isOpen,
  defaultOpen = false,
  children,
}: AccordionProviderProps) {
  const { openAccordionId, setOpenAccordionId, allowMultiple } = useAccordionGroupContext() || {};

  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(defaultOpen);

  const openAccordion = useCallback(() => {
    setIsAccordionOpen(true);
    setOpenAccordionId && setOpenAccordionId(id);
  }, [id, setOpenAccordionId]);

  const closeAccordion = useCallback(() => {
    setIsAccordionOpen(false);
    setOpenAccordionId && setOpenAccordionId(undefined);
  }, [setOpenAccordionId]);

  const toggleAccordion = useCallback(() => {
    if (isAccordionOpen) closeAccordion();
    else openAccordion();
  }, [closeAccordion, isAccordionOpen, openAccordion]);

  useEffect(() => {
    if (setOpenAccordionId && isAccordionOpen && !openAccordionId) setOpenAccordionId(id);
  }, [id, isAccordionOpen, openAccordionId, setOpenAccordionId]);

  useEffect(() => {
    if (isOpen === true) openAccordion();
    else if (isOpen === false) closeAccordion();
  }, [closeAccordion, isOpen, openAccordion]);

  useEffect(() => {
    if (allowMultiple !== false && openAccordionId) setIsAccordionOpen(openAccordionId === id);
  }, [allowMultiple, id, openAccordionId]);

  return (
    <AccordionContext.Provider
      value={{
        isAccordionOpen,
        openAccordion,
        closeAccordion,
        toggleAccordion,
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
}

export function useAccordionContext() {
  const accordionContext = useContext(AccordionContext);

  if (!accordionContext)
    throw new Error("useAccordionContext can only be used within <AccordionProvider>");

  return accordionContext;
}
