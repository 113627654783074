"use client";

import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";

import { useFilterContext } from "@/contexts/filter-context";
import { useSearchParamsContext } from "@/contexts/search-params-context";
import { type FilterField } from "@/lib/forms";
import { areArraysEqual, isDefinedAndNotEmpty } from "@/lib/utils";

export type Filter = {
  name: FilterField;
  values?: string[];
};

export function useFilter() {
  const { getSearchParams, resetSearchParams, checkSearchParams } = useSearchParamsContext();
  const { currentFilters, setCurrentFilters, isLoading, setIsLoading, setIsActive } =
    useFilterContext();

  const router = useRouter();
  const pathname = usePathname();

  const mergeFilters = (filters: Filter[]) => {
    return filters.reduce<Filter[]>(
      (updatedFilters, { name, values }) => {
        const existingFilter = updatedFilters.find((filter) => filter.name === name);

        if (!isDefinedAndNotEmpty(existingFilter)) {
          if (isDefinedAndNotEmpty(values)) updatedFilters.push({ name, values });
        } else if (isDefinedAndNotEmpty(values)) existingFilter.values = values;
        else return updatedFilters.filter((filter) => filter.name !== name);

        return updatedFilters;
      },
      currentFilters.map(({ name, values }) => ({ name, values })),
    );
  };

  const updateFilters = (filters: Filter[]) => {
    const newFilters = mergeFilters(filters);

    if (areArraysEqual(newFilters, currentFilters)) return;

    setCurrentFilters(newFilters);
    setIsLoading(true);

    router.push(`${pathname}?${getSearchParams(filters)}`, { scroll: false });
  };

  const resetFilters = () => {
    setCurrentFilters([]);
    setIsLoading(true);
    setIsActive(false);

    router.push(`${pathname}?${resetSearchParams()}`, { scroll: false });
  };

  useEffect(() => {
    if (!isLoading) setIsActive(checkSearchParams());
  }, [checkSearchParams, isLoading, setIsActive]);

  return { currentFilters, updateFilters, resetFilters };
}
