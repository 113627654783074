import { type VariantProps } from "cva";
import { type HTMLAttributes } from "react";

import { Milestone } from "@/components/milestone";
import { cva, cx, isDefinedAndNotEmpty } from "@/lib/utils";
import { type SanityEventMilestone } from "@/sanity/schemas/documents/collections/event";

export type MilestonesProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    milestones: SanityEventMilestone[] | undefined;
  };

const variants = cva({
  base: "grid grid-cols-12 gap-y-6 md:gap-x-8",
});

export function Milestones({ milestones, className, ...props }: MilestonesProps) {
  return (
    isDefinedAndNotEmpty(milestones) && (
      <div className={cx(variants(), className)} {...props}>
        {milestones.map((milestone, index) => {
          return (
            <Milestone
              key={index}
              milestone={milestone}
              className="col-span-12 md:col-span-6 xl:col-span-4"
            />
          );
        })}
      </div>
    )
  );
}
