"use client";

import { type VariantProps } from "cva";
import { Children, cloneElement, type HTMLAttributes, isValidElement } from "react";
import { useLabel } from "react-aria";

import { Label } from "@/components/forms/label";
import { Select } from "@/components/forms/select";
import { cva, cx } from "@/lib/utils";

export type FieldProps = HTMLAttributes<HTMLElement> & VariantProps<typeof variants>;

export const variants = cva({
  base: "flex flex-col gap-2 py-4",
});

const types = [Select];

export function Field({ children, className, ...props }: FieldProps) {
  const label = Children.map(children, (child) =>
    isValidElement(child) && child.type === Label ? (child.props.children as string) : undefined,
  );

  const { labelProps, fieldProps } = useLabel({ ...props, label });

  return (
    <div className={cx(variants({}), className)} {...props}>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          if (child.type === Label) return cloneElement(child, labelProps);
          if (types.some((type) => child.type === type)) return cloneElement(child, fieldProps);
        }

        return child;
      })}
    </div>
  );
}
