"use client";

import { useSearchParams } from "next/navigation";
import { createContext, type ReactNode, useContext } from "react";

import { isDefinedAndNotEmpty } from "@/lib/utils";
import { type NextSearchParamKey } from "@/types";

type SearchParamsContextValues = {
  searchParams: URLSearchParams | undefined;
  searchParamKeys: NextSearchParamKey[];
  getSearchParams: (set: SearchParam[]) => string;
  resetSearchParams: () => string;
  checkSearchParams: () => boolean;
};

export type SearchParam = {
  name: string;
  values?: string[];
};

type SearchParamsProviderProps = {
  children: ReactNode;
};

const SearchParamsContext = createContext<SearchParamsContextValues | undefined>(undefined);

export function SearchParamsProvider({ children }: SearchParamsProviderProps) {
  const searchParams = useSearchParams();
  const searchParamKeys: NextSearchParamKey[] = [
    "theme",
    "topic",
    "year",
    "month",
    "past-events",
    "view",
  ];

  const getSearchParams = (set: SearchParam[]) => {
    const urlSearchParams = new URLSearchParams(searchParams);

    set.map(({ name, values }) => {
      if (isDefinedAndNotEmpty(values)) values.map((value) => urlSearchParams.set(name, value));
      else urlSearchParams.delete(name);
    });

    return String(urlSearchParams);
  };

  const resetSearchParams = () => {
    const urlSearchParams = new URLSearchParams(searchParams);

    searchParamKeys.map((key) => urlSearchParams.delete(key));

    return String(urlSearchParams);
  };

  const checkSearchParams = () => {
    const urlSearchParams = new URLSearchParams(searchParams);

    return searchParamKeys.some((key) => urlSearchParams.has(key));
  };

  return (
    <SearchParamsContext.Provider
      value={{
        searchParams,
        searchParamKeys,
        getSearchParams,
        resetSearchParams,
        checkSearchParams,
      }}
    >
      {children}
    </SearchParamsContext.Provider>
  );
}

export function useSearchParamsContext() {
  const searchParamsContext = useContext(SearchParamsContext);

  if (!searchParamsContext)
    throw new Error("useSearchParamsContext can only be used within <SearchParamsProvider>");

  return searchParamsContext;
}
