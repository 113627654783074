"use client";

import { IconChevronDown } from "@tabler/icons-react";
import { type VariantProps } from "cva";
import { AnimatePresence, type HTMLMotionProps, motion, type Variants } from "framer-motion";
import { type HTMLAttributes, type ReactNode } from "react";
import { useId } from "react-aria";
import { Button, type ButtonProps } from "react-aria-components";

import { AccordionProvider, useAccordionContext } from "@/contexts/accordion-context";
import {
  AccordionGroupProvider,
  type AccordionGroupProviderProps,
} from "@/contexts/accordion-group-context";
import { cva, cx, isCallable, isDefinedAndNotEmpty } from "@/lib/utils";

export type AccordionProps = HTMLAttributes<HTMLElement> &
  VariantProps<typeof variants> & {
    header: ReactNode | ((isOpen: boolean) => ReactNode);
    defaultOpen?: boolean;
    isOpen?: boolean;
    isDisabled?: boolean;
    animateContent?: boolean;
    headerPosition?: "top" | "bottom";
    showArrow?: boolean;
  };

export type AccordionGroupProps = AccordionGroupProviderProps;

export type AccordionHeaderProps = ButtonProps & {
  children: ReactNode;
  headerId: string;
  contentId: string;
  showArrow?: boolean;
};

export type AccordionContentProps = HTMLMotionProps<"div"> & {
  children: ReactNode;
  contentId: string;
  headerId: string;
  animateContent?: boolean;
};

const variants = cva({
  variants: {
    spacing: {
      none: "",
      bottom: "[&>[role=region]_>:first-child]:pb-4",
      top: "[&>[role=region]_>:first-child]:pt-4",
    },
  },
  defaultVariants: {
    spacing: "top",
  },
});

const duration = 0.4;

const itemAnimationVariants: Variants = {
  open: { height: "auto", transition: { duration, ease: "easeInOut" } },
  closed: { height: "0", transition: { duration, ease: "easeInOut" } },
};

const itemChildAnimationVariants: Variants = {
  open: { opacity: 1, transition: { duration: duration / 2, delay: duration, ease: "easeInOut" } },
  closed: { opacity: "0", transition: { duration: duration / 2, ease: "easeInOut" } },
};

function AccordionHeader({
  children,
  headerId,
  contentId,
  showArrow = true,
  ...props
}: AccordionHeaderProps) {
  const { isAccordionOpen, toggleAccordion } = useAccordionContext();

  return (
    <Button
      id={headerId}
      onPress={toggleAccordion}
      aria-expanded={isAccordionOpen}
      aria-controls={contentId}
      className={cx("no-focus focus-ring box-border flex w-full items-center justify-between")}
      {...props}
    >
      {children}
      {showArrow && (
        <span aria-hidden="true">
          <IconChevronDown
            strokeWidth={1.5}
            className="size-4 text-current transition-transform duration-300"
            style={{
              transform: isAccordionOpen ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </span>
      )}
    </Button>
  );
}

function AccordionContent({
  children,
  contentId,
  headerId,
  animateContent,
}: AccordionContentProps) {
  const { isAccordionOpen } = useAccordionContext();

  return (
    <AnimatePresence initial={false}>
      {isAccordionOpen && (
        <motion.div
          id={contentId}
          variants={itemAnimationVariants}
          initial="closed"
          animate="open"
          exit="closed"
          role="region"
          aria-labelledby={headerId}
          aria-hidden={!isAccordionOpen}
          className={cx(!animateContent && "overflow-hidden")}
        >
          <motion.div
            variants={animateContent ? itemChildAnimationVariants : undefined}
            initial="closed"
            animate="open"
            exit="closed"
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export function AccordionGroup({ children, ...props }: AccordionGroupProps) {
  return <AccordionGroupProvider {...props}>{children}</AccordionGroupProvider>;
}

export function Accordion({
  header,
  defaultOpen,
  isOpen,
  isDisabled,
  animateContent,
  headerPosition = "top",
  showArrow,
  children,
  spacing,
  className,
  ...props
}: AccordionProps) {
  const accordionId = useId();
  const headerId = useId();
  const contentId = useId();

  if (!isDefinedAndNotEmpty(children)) return isCallable(header) ? header(false) : header;

  const AccordionHeaderSlot = () => {
    const { isAccordionOpen } = useAccordionContext();

    return (
      <AccordionHeader
        headerId={headerId}
        contentId={contentId}
        showArrow={showArrow}
        isDisabled={isDisabled}
      >
        {isCallable(header) ? header(isAccordionOpen) : header}
      </AccordionHeader>
    );
  };

  return (
    isDefinedAndNotEmpty(header) &&
    isDefinedAndNotEmpty(children) && (
      <div className={cx(variants({ spacing: spacing || headerPosition }), className)} {...props}>
        <AccordionProvider id={accordionId} isOpen={isOpen} defaultOpen={defaultOpen}>
          {headerPosition === "top" && <AccordionHeaderSlot />}
          <AccordionContent
            contentId={contentId}
            headerId={headerId}
            animateContent={animateContent}
          >
            {children}
          </AccordionContent>
          {headerPosition === "bottom" && <AccordionHeaderSlot />}
        </AccordionProvider>
      </div>
    )
  );
}
