"use client";

import { IconCalendar } from "@tabler/icons-react";

import { Field } from "@/components/forms/field";
import { Label } from "@/components/forms/label";
import { Select } from "@/components/forms/select";
import { useFilterContext } from "@/contexts/filter-context";
import { useFilter } from "@/hooks/use-filter";
import { getDate } from "@/lib/dates";
import { type Field as FieldType, type FilterField } from "@/lib/forms";
import { isDefinedAndNotEmpty } from "@/lib/utils";
import { type SanityYear } from "@/sanity/types";

export type YearFilterProps = {
  years: SanityYear[];
};

const filterName: FilterField = "year";

export function YearFilter({ years }: YearFilterProps) {
  const { isLoading, getCurrentFilterValue } = useFilterContext();
  const { updateFilters } = useFilter();

  const currentYear = getDate().getFullYear();

  const currentYearFilter = getCurrentFilterValue(filterName);

  const options = years.reduce<FieldType>((options, { year, eventsCount }) => {
    if (isDefinedAndNotEmpty(year))
      options[year] = { label: `${year} (${eventsCount})`, value: year };

    return options;
  }, {});

  const disabledKeys = years.reduce<string[]>((disabledKeys, { year, eventsCount }) => {
    if (isDefinedAndNotEmpty(year) && eventsCount === 0) disabledKeys.push(year);

    return disabledKeys;
  }, []);

  return (
    <Field className="py-0">
      <Label className="sr-only">Select Year</Label>
      <Select
        name={filterName}
        icon={IconCalendar}
        placeholder={String(currentYear)}
        selectedKey={currentYearFilter ?? null}
        onSelectionChange={(key) => {
          updateFilters([{ name: filterName, values: [String(key)] }]);
        }}
        options={options}
        disabledKeys={disabledKeys}
        isDisabled={isLoading}
        isActive={isDefinedAndNotEmpty(currentYearFilter)}
      />
    </Field>
  );
}
